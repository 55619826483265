.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
    /* width: 60%; */
}

.add-user .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.text-box-size{
    height: 30px;
}