.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
  }
  
  .horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .add-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  
  .delete-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: "#008fbd";
    background-color: "#fff";
  }
  .delete-user-button:disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }
  
  .table-cell-header {
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  
  .react-search-field-input {
    padding: 0 !important;
    width: 300px;
  }
  
  .headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 17px !important;
  }
  
  .tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5);
  }
  .heading{
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #FFF !important;
    padding-top: 6px;
  }
  
  .tableIh{
    width: 0px !important;
  }
  
  .Menu-item{
   background-color: #384089 !important;
   color:#fff !important
  }
  
  .MuiList-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }