.MuiAlert-root {
    width: 100%;
    justify-content: center;
    text-align: center;
    padding: 0 !important;
}

.MuiAlert-message {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */
    text-align: center;
    color: #FFFFFF;
    /* padding-top: 10px;
    padding-bottom: 10px; */
}