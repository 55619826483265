.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
}

.add-user .innerDiv{
    border: 1px solid #C2C2C2;
    padding: 1rem;
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.heading{
    font-size: 16px;
    color: rgb(90, 87, 87);
    font-weight: normal;
    line-height: 19px;
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.MuiSelect-select {
    padding: 5px !important;
}