.reset-password .outerDiv{
    background-color: #ffffff;
    margin-top: 2rem;
    padding: 2rem;
    /* width: 60%; */
}

.reset-password .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.reset-password .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}