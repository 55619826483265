.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 0px !important;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.react-search-field-input {
    padding: 0 !important;
    width: 300px;
}

.headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 15px;
}

.tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5)
}
.heading{
    /* border: 1px solid; */
    /* text-align: left !important; */
    font-weight: 500 !important;
    font-size: 15px !important;
    /* color: #384089 !important; */
    color: #FFF !important;
    padding-top: 6px;
}
.grayButton{
    background: #A9A9A9 !important;
    color: #FFFFFF !important;
    border: 1px solid #A9A9A9 !important;
    font-size: 14px !important;
    /* min-width: 60px !important; */
    font-weight: 600 !important;
    /* height: 25px !important; */
    /* width: 60px !important; */
    /* border-radius: 5px !important; */
    text-transform: none !important;
}

.secondayButton{
    background: #FFFFFF !important;
    color: #008fbd !important;
    border: 1px solid #384089 !important;
    font-size: 14px !important;
    /* min-width: 60px !important; */
    font-weight: 600 !important;
    /* height: 25px !important; */
    /* width: 60px !important; */
    /* border-radius: 5px !important; */
    text-transform: none !important;
}

.primaryButton{
    background: #384089 !important;
    color: #FFFFFF !important;
    border: 1px solid #384089 !important;
    font-size: 14px !important;
    /* min-width: 60px !important; */
    font-weight: 600 !important;
    /* height: 25px !important; */
    /* width: 60px !important; */
    /* border-radius: 5px !important; */
    text-transform: none !important;
}

.MuiPaginationItem-page.Mui-selected{
    background-color: #b5dfec !important;
}

.cursorPointer{
    cursor: pointer !important;
}

.serachLabel {
    top: 14.67% !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 10px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.06em !important;
    text-transform: uppercase !important;
    color: #676984 !important;
}

.filterBy .MuiFormControl-root .MuiOutlinedInput-root {
    padding: 0px !important;
}

.filterBy .MuiFormControl-root label{
    top: -8px;
}

.search .MuiOutlinedInput-root input{
    border: 0px !important;
}