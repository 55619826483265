.left-div{
    background-color: #F8F8FF;
    min-height: 100vh;
}

.right-div{
    background-color: #ffffff;
}

.centerized-div{
    display: flex;
    flex: 1;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.right-heading{
    margin-left: 20px !important;
}

.login-page .login-heading{
    justify-content: center;
    align-items: center;
    margin-top: 20% !important;
}

.login-page .MuiInputBase-input{
    border: none !important;
}

.login-page .MuiInputBase-root{
    width: 100% !important;
}

.login-page .login-button{
    text-align: center;
    margin-top: 2rem;
}

  