.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.dialog-title-close-icon {
    text-align: end;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 46%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.dialog-box-title {
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #202020;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #202020;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}

.makeStyles-dialogPaper-5 {
    height: 500px;
    /* width: 500px; */
    max-width: fit-content;
}
/* .MuiDialog-paperWidthSm {
    max-width: fit-content;
} */

.makeStyles-dialogPaper-25{
    height: 500px;
}

.policy-attach-container{
    border: 0.5px solid grey !important ;
}