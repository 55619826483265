.inputAdornment{
    background-color: #FFFFFF !important;
    border: 1px solid #E5E5E5 !important;
}

.passwordField .MuiInputBase-input{
    border: 0px !important;
}



