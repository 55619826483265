@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NunitoSans';
  src: local('NunitoSans'), url(/static/media/NunitoSans-Regular.87d92665.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(/static/media/OpenSans-Regular.ac568e1f.ttf) format('truetype');
}

.body {
  font-family: "NunitoSans", sans-serif;
}
.App {
  /* background-color: #FBFBFB; */
  background-color:  #F0F0F7;
  min-height: 100vh;
}

.invite-user .outerDiv{
    background-color: #ffffff;
    margin-top: 2rem;
    padding: 2rem;
    /* width: 60%; */
}

.invite-user .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.invite-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}
.MuiFilledInput-input{
    padding: 0.8rem !important;
}

.MuiInputBase-input{
    border: 1px solid #E5E5E5 !important;
    background: #ffffff !important;
}

.MuiInputBase-isValidOrInvalid{
    border: 1px solid #84199e !important;
    background: #ffffff !important;
}
.MuiButton-root.MuiButton-root, .MuiButton-root.MuiButton-root:hover{
    color: #FFFFFF;
    /* background-color: #008FBD;
    border: 1.5px solid #008FBD; */
    background-color: #384089;
    border: 1.5px solid #384089;
    min-height: 35px;
    min-width: 100px;
    height: 35px;
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.MuiButton-root.Mui-disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }

/* Custom, galaxy fold*/ 
@media only screen and (min-width : 280px) {
    .MuiButton-root.MuiButton-root{
        height: 50px;
    }
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    .MuiButton-root.MuiButton-root{
    height: 50px;
 }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    .MuiButton-root.MuiButton-root{
        height: 50px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .MuiButton-root.MuiButton-root{
        height: 35px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .MuiButton-root.MuiButton-root{
        height: 35px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .MuiButton-root{
        height: 35px;
    }
}
.MuiAppBar-colorPrimary {
    color: #2F3484  !important;
    /* background-color: #F8F8FF !important; */
    background-color: #384089 !important;

}

.MuiPaper-elevation4{
    box-shadow: none !important;
}

.menuButton, .menuButton:hover{
    background-color: white !important;
    border-radius: 4px !important;
    margin: -14px !important;
    padding: 0px 10px 0px 0px !important;
}
.dialog-title-close-icon {
    text-align: end;
}

.dialog-box-title {
    font-size: 22px !important;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #384089 !important;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #384089;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}
.MuiAlert-root {
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    padding: 0 !important;
}

.MuiAlert-message {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */
    text-align: center;
    color: #FFFFFF;
    /* padding-top: 10px;
    padding-bottom: 10px; */
}
.login-input-border .MuiInput-underline:before .MuiInput-underline:before {
  border-bottom: 0px;
}

.labelTextbox {
  height: 38.56px;
  bottom: 76.77%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  color: #676984 !important;
}

.UserNameInput {
  top: 10% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #676984 !important;
}

.inputRootContainer {
  max-width: 620px !important;
  width: 100% !important;
}

.inputRootContainerForget {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  display: -webkit-flex;
  display: flex;
}

.inputRootSubContainer {
  margin-left: 30px;
  margin-top: 6px;
}

.RemeberMeContainer {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}

.checkboxRemeberME {
  width: 30px;
}

.loginButton.MuiButton-root:hover {
  background: #264c86 !important;
}

@media only screen and (max-width: 550px) {
  .loginButton {
    width: 92%;
    max-width: 350px;
    margin-bottom: 20px !important;
  }
}

@media only screen and (min-width: 600px) {
  .loginButton {
    width: 160px;
  }
}

.loginButton {
  font-size: 11px;
  text-transform: uppercase;
  background-color: #264c86 !important;
  border: 1.5px solid #264c86;
  color: #ffffff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.errorMessageAlert {
  margin-left: 35px !important;
  margin-top: 10px !important;
  color: #273050;
  background: rgba(192, 29, 29, 0.05);
  border: 1px solid #e96262;
  width: 80%;
  max-width: 592px;
  display: -webkit-flex;
  display: flex;
  height: 50px;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 10px;
}

.alertMessageImage {
  margin-left: 10px;
  margin-right: 10px;
}

.loginMobileText {
  position: absolute;
  top: 30px;
  left: 20px;
  color: #ffffff;
  font-size: 21px;
  line-height: 26px;
}

@media only screen and (max-width: 550px) {
  .TextBoxMobileView {
    margin-left: 15px;
    width: 95.5%;
  }
}

@media only screen and (min-width: 600px) {
  .TextBoxMobileView {
    margin-left: 25px;
    width: 89%;
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxMobileView {
    margin-right: 8% !important;
    margin-left: 14px !important;
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxMobileView {
    margin-left: 25px;
  }
}

@media only screen and (min-width: 960px) {
  .inputBoxMobileView {
    margin-left: 25px;
    margin-top: 30px
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxMobileView1 {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxMobileView1 {
    margin-left: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .inputBoxMobileView1 {
    margin-left: 25px;
    margin-top: 20px !important
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxPassword {
    margin-left: 13px !important
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxPassword {
    margin-left: 25px;
  }
}

.registerButton{
  margin-top: 20px !important;
  margin-bottom: 20px;
}

@media only screen and (max-width: 550px) {
  .registerButton {
    margin-left: 20px !important;
    margin-right: 5% !important;
  }
}

@media only screen and (min-width: 600px) {
  .registerButton {
    margin-left: 30px !important;
    margin-right: 15% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .registerButton {
    margin-right: 15% !important;
    -webkit-align-items: end;
            align-items: end;
    text-align: right;
  }
}

@media only screen and (max-width: 550px) {
  .registerButton1 {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .registerButton1 {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .registerButton1 {
    max-width: 160px;
  }
}

.contactNumber{
  margin-top: 15px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 550px) {
  .contactNumber {
    margin-left: 23px !important;
    width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .contactNumber {
    margin-left: 35px;
    width: 86%
  }
}

.visibilityButton .MuiIconButton-root{
  padding: 0px !important;
}

.react-tel-input .form-control {
  height: 40px !important;
  width: 104% !important;
}

@media only screen and (max-width: 550px) {
  .NewPassword {
    width: 96%
  }
}

@media only screen and (min-width: 600px) {
  .NewPassword {
    width: 91%;
  }
}

@media only screen and (max-width: 550px) {
  .ConfirmPassword {
    width: 96%
  }
}

@media only screen and (min-width: 600px) {
  .ConfirmPassword {
    width: 101%;
  }
}

.MuiOutlinedInput-input{
  border: 0px !important;
}
.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-track {
    background: #c4c4c4;
    border: 0.5px solid #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
    /* border: 3px solid orange; */
  }
  /* @media only screen and (min-width : 1200px) {
    .MuiInputBase-root{
        width: 323px !important ;
    }
}
   */
.inputAdornment{
    background-color: #FFFFFF !important;
    border: 1px solid #E5E5E5 !important;
}

.passwordField .MuiInputBase-input{
    border: 0px !important;
}

.left-div{
    background-color: #F8F8FF;
    min-height: 100vh;
}

.right-div{
    background-color: #ffffff;
}

.centerized-div{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    min-height: 100vh;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0;
}

/* .centerized-inner-div{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.login-form{
    margin-top: -80%;
    padding: 2rem;
} */

.login-page .login-heading{
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20% !important;
}

.login-page .MuiInputBase-input{
    border: none !important;
}

.login-page .MuiInputBase-root{
    width: 100% !important;
}

.login-page .login-button{
    text-align: center;
    margin-top: 2rem;
}
.left-div{
    background-color: #F8F8FF;
    min-height: 100vh;
}

.right-div{
    background-color: #ffffff;
}

.centerized-div{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    min-height: 100vh;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0;
}

.right-heading{
    margin-left: 20px !important;
}

.login-page .login-heading{
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20% !important;
}

.login-page .MuiInputBase-input{
    border: none !important;
}

.login-page .MuiInputBase-root{
    width: 100% !important;
}

.login-page .login-button{
    text-align: center;
    margin-top: 2rem;
}

  
.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-track {
    background: #c4c4c4;
    border: 0.5px solid #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
    /* border: 3px solid orange; */
  }
  @media only screen and (min-width : 1200px) {
    .MuiInputBase-root{
        width: 100% !important ;
    }
}
  
.login-input-border{
    border: 1px solid transparent !important;
    background: #ffffff !important;
}

/* .MuiInputBase-input{
    border: 1px solid transparent !important;
    background: #ffffff !important;
} */
.dialog-title-close-icon {
    text-align: end;
}

.dialog-box-title {
    font-size: 22px !important;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #384089 !important;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #384089 !important;
    margin-top: 0px !important;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}
.forgot-password .outerDiv{
    background-color: #ffffff;
    margin-top: 2rem;
    padding: 2rem;
    /* width: 60%; */
}

.forgot-password .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.forgot-password .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}
.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-track {
    background: #c4c4c4;
    border: 0.5px solid #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
    /* border: 3px solid orange; */
  }
  @media only screen and (min-width : 1200px) {
    .MuiInputBase-root{
        width: 100%  ;
    }
}
  
.reset-password .outerDiv {
  background-color: #ffffff;
  margin-top: 2rem;
  padding: 2rem;
  /* width: 60%; */
}

.reset-password .innerDiv {
  /* border: 2px solid #E5E5E5; */
  border: 1px solid #c2c2c2;
  padding: 1rem;
  /* margin-top: 2rem; */
}

.reset-password .horizontal-line {
  margin: 0.8rem -1rem 1rem -1rem;
  border-bottom: 1px solid #e5e5e5;
}

.textCaption {
  font-weight: 700 !important;
  font-size: 26px !important;
  line-height: 30px;
  margin-left: 40px !important;
  max-width: 313px;
  color: #254b83;
}

@media only screen and (min-width: 800px) {
  .textCaption {
    margin-top: 240px !important;
  }
}

@media only screen and (max-width: 780px) {
  .textCaption {
    margin-top: 130px !important;
  }
}

.loginMobileTextCaption {
  position: absolute;
  top: 70px;
  left: 20px;
  color: #ffffff;
  font-size: 28px;
  line-height: 26px;
}

.confirmButtonReset {
  margin-top: 30px !important;
  /* margin-left: 35px !important; */
  /* width: 120px; */
  font-size: 20px;
}

@media only screen and (min-width: 800px) {
  .confirmButtonReset {
    width: 120px !important;
    margin-left: 35px !important;
  }
}

@media only screen and (min-width: 760px) {
  .confirmButtonReset {
    width: 75%;
    margin-left: 35px !important;
  }
}

@media only screen and (max-width: 750px) {
  .confirmButtonReset {
    width: 80%;
    margin-left: 25px !important;
    height: 35px !important;
  }
}
.inputAdornment{
    background-color: #FFFFFF !important;
    border: 1px solid #E5E5E5 !important;
}

.passwordField .MuiInputBase-input{
    border: 0px !important;
}




.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 2rem;
    padding: 2rem;
    /* width: 60%; */
}

.add-user .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}
.nav-route-container {
  height: 100%;
  background-color: #F0F0F7;
  padding: 5px;
  /* display: flex; */
}

.horizontal-line {
  margin: 0rem 0.3rem 0.2rem 0.3rem;
  border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 5px 21px;
  width: 160px;
  height: 30px;
  background: rgba(0, 143, 189, 0.95);
  border-radius: 3px;
}

.delete-user-button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 5px;
  width: 170px;
  height: 30px;
  border: 1px solid #008fbd;
  box-sizing: border-box;
  border-radius: 3px;
  color: "#008fbd";
  background-color: "#fff";
}
.delete-user-button:disabled {
  background-color: #f0f8ff !important;
  border: 1.5px solid #a9a9a9 !important;
  color: #a9a9a9 !important;
}

.table-cell-header {
  border: 1px solid #e5e5e5;
  width: 20%;
  padding: 10px !important;
  background-color: #f0f8ff;
}

.react-search-field-input {
  padding: 0 !important;
  width: 300px;
}

.headerCell {
  color: #384089 !important;
  font-weight: 600 !important;
  font-size: 17px !important;
}

.tableBorderRightH {
  border-right: 2px solid rgba(224, 224, 224, .5);
  border-bottom: 2px solid rgba(224, 224, 224, .5);
}
.heading{
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #FFF !important;
  padding-top: 6px;
}

.tableIh{
  width: 0px !important;
}

.Menu-item{
 background-color: #384089 !important;
 color:#fff !important
}

.MuiList-padding{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.notExistData{
  margin-top: 100px;
  color: #384089 !important;
  font-size: 20px !important;
  font-weight: 500;
  text-align: center;
}
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
    /* width: 60%; */
}

.add-user .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.autocomplelteWidth{
    width: 500px;
}
.root {
  width: 100vw;
  height: 88.5vh;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  /* flex-grow: 1; */
  background-color: #F0F0F7;
}

.nav-container {
  /* height: 100%; */
  height: 88.5vh;
  background-color: "#ffffff";
  overflow-y: hidden;
  overflow-x: hidden;
}

.nav-route-container {
  height: 100%;
  background-color: #F0F0F7;
  padding: 5px;
  /* display: flex; */
}
.nav-route-inner-container {
  height: 100%;
  margin: 10px;
  background-color: green;
}
.horizontal-line {
  margin: 0rem 0.3rem 0.2rem 0.3rem;
  border-bottom: 1px solid #e5e5e5;
}
.navigation-top-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-transform: uppercase;

  /* Heading */

  color: #4b4c63;
}
.navigation-main-item-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: normal;
  font-size: "14px";
  line-height: "19px";
  /* identical to box height */

  text-transform: uppercase;

  /* Heading */

  color: #4b4c63;
}
.navigation-subitem-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: normal;
  font-size: "14px";
  line-height: "19px";
  /* identical to box height */

  text-transform: uppercase;

  /* Heading */

  color: #4b4c63;
}
.dashboard-right-view {
  background-color: #ffffff;
  /* height: 100vh; */
  padding: 2rem;
}

.MuiDivider-root.MuiDivider-root {
  width: 100%;
  background-color: #c2c2c2;
  /* background-color: #525252; */
  margin: 0.8rem 0 1rem 0;
}
.option-second-row {
  margin-top: 2rem !important;
}

.nav-container {
  /* height: 100%; */
  height: 88.5vh;
  background-color: "#ffffff";
  overflow-y: hidden;
  overflow-x: hidden;
}

.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    /* display: flex; */
}
.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
}
  .add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  .delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
  }
  .table-cell-header {
    /* border: "1px solid #E5E5E5",
      width: "20%",
      padding: "10px",
      backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  .policy-container {
    border: 1px solid #000 ;
  }

  .search-class{
  color: #000;
  }

  .react-search-field-input{
    padding: 0 !important;
    width: 700px;
  }

  .view-button{
    text-decoration:underline;
    /* border-bottom: 1px solid black; */
  }

.users-all {
   background-color: white !important;
   color: #008fbd !important;
   border: #fff !important;
   min-height: 15px !important;
   font-size: 14px !important;
   padding-top: 10px !important;
   font-weight: 500 !important;
   text-decoration: underline !important;
   padding-left: 30px !important;
}
.users-all:disabled {
  background-color: white !important;
  color: #a9a9a9 !important;
  border: #fff !important;
  min-height: 15px !important;
   font-size: 14px !important;
   padding-top: 10px !important;
   font-weight: 500 !important;
   text-decoration: underline !important;
   padding-left: 30px !important;
}

.account-user{
  font-size: "14px" !important;
  width: "190px" !important;
  color: "black" !important;
  padding-top: "5px" !important;
  padding-left: "9000px" !important;
}

.Menu-item{
  background-color: #384089 !important;
  color:#fff !important
 }
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.dialog-title-close-icon {
    text-align: end;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 46%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.dialog-box-title {
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #202020;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #202020;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}

.makeStyles-dialogPaper-5 {
    height: 500px;
    /* width: 500px; */
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
/* .MuiDialog-paperWidthSm {
    max-width: fit-content;
} */

.makeStyles-dialogPaper-25{
    height: 500px;
}

.policy-attach-container{
    border: 0.5px solid grey !important ;
}
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
    /* width: 60%; */
}

.add-user .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.react-search-field-input{
    padding: 0 !important;
    width: 642px;
}

.MuiAccordion-root.Mui-expanded{
    margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded{
    margin: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded{
    min-height: 0 !important;
}

.MuiAccordionSummary-content{
    margin: 0;
}

.filterBy .MuiFormControl-root .MuiOutlinedInput-root {
    padding: 0px !important;
}

.filterBy .MuiFormControl-root label{
    top: -8px;
}

.search .MuiOutlinedInput-root input{
    border: 0px !important;
}
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
    /* width: 60%; */
}

.add-user .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.text-box-size{
    height: 30px;
}
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
    /* width: 60%; */
}

.add-user .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 0px !important;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.react-search-field-input {
    padding: 0 !important;
    width: 300px;
}

.headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 15px;
}

.tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5)
}
.heading{
    /* border: 1px solid; */
    /* text-align: left !important; */
    font-weight: 500 !important;
    font-size: 15px !important;
    /* color: #384089 !important; */
    color: #FFF !important;
    padding-top: 6px;
}
.grayButton{
    background: #A9A9A9 !important;
    color: #FFFFFF !important;
    border: 1px solid #A9A9A9 !important;
    font-size: 14px !important;
    /* min-width: 60px !important; */
    font-weight: 600 !important;
    /* height: 25px !important; */
    /* width: 60px !important; */
    /* border-radius: 5px !important; */
    text-transform: none !important;
}

.secondayButton{
    background: #FFFFFF !important;
    color: #008fbd !important;
    border: 1px solid #384089 !important;
    font-size: 14px !important;
    /* min-width: 60px !important; */
    font-weight: 600 !important;
    /* height: 25px !important; */
    /* width: 60px !important; */
    /* border-radius: 5px !important; */
    text-transform: none !important;
}

.primaryButton{
    background: #384089 !important;
    color: #FFFFFF !important;
    border: 1px solid #384089 !important;
    font-size: 14px !important;
    /* min-width: 60px !important; */
    font-weight: 600 !important;
    /* height: 25px !important; */
    /* width: 60px !important; */
    /* border-radius: 5px !important; */
    text-transform: none !important;
}

.MuiPaginationItem-page.Mui-selected{
    background-color: #b5dfec !important;
}

.cursorPointer{
    cursor: pointer !important;
}

.serachLabel {
    top: 14.67% !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 10px !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center !important;
            align-items: center !important;
    letter-spacing: 0.06em !important;
    text-transform: uppercase !important;
    color: #676984 !important;
}

.filterBy .MuiFormControl-root .MuiOutlinedInput-root {
    padding: 0px !important;
}

.filterBy .MuiFormControl-root label{
    top: -8px;
}

.search .MuiOutlinedInput-root input{
    border: 0px !important;
}
.reset-password .outerDiv{
    background-color: #ffffff;
    margin-top: 2rem;
    padding: 2rem;
    /* width: 60%; */
}

.reset-password .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.reset-password .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.react-search-field-input {
    padding: 0 !important;
    width: 300px;
}
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
}

.add-user .innerDiv{
    border: 1px solid #C2C2C2;
    padding: 1rem;
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.iconAllow{
    color: #384089;
    margin: 5px;
    padding: 1px;
    cursor: pointer;
}

.iconNotAllow{
    color: #827e7e;
    margin: 5px;
    padding: 1px;
    cursor: not-allowed;
}

.grid-container {
    display: grid;
    grid-column-gap: 5px;
  }
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
}

.add-user .innerDiv{
    border: 1px solid #C2C2C2;
    padding: 1rem;
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.heading{
    font-size: 16px;
    color: rgb(90, 87, 87);
    font-weight: normal;
    line-height: 19px;
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.dialog-title-close-icon {
    text-align: end;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 46%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.dialog-box-title {
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #202020;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #202020;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    text-align: center;
}

.makeStyles-dialogPaper-5 {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.dialog-title-close-icon {
    text-align: end;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 46%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.dialog-box-title {
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #202020;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #202020;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}

.makeStyles-dialogPaper-5 {
    /* height: 500px; */
    /* width: 500px; */
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
/* .MuiDialog-paperWidthSm {
    max-width: fit-content;
} */

/* .makeStyles-dialogPaper-25{
    height: 500px;
} */
.MuiPaper-elevation1 {
    /* box-shadow: 0px 2px 1px -1px #f8f8ff ; */
    box-shadow: 0px 2px 1px -1px #2f3484, 0px 1px 1px 0px #202020, 0px 1px 3px 0px #4b4c63  !important;
}
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
  }
  
  .horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  
  .delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: "#008fbd";
    background-color: "#fff";
  }
  .delete-user-button:disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }
  
  .table-cell-header {
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  
  .react-search-field-input {
    padding: 0 !important;
    width: 300px;
  }
  
  .headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 17px !important;
  }
  
  .tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5);
  }
  .heading{
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #FFF !important;
    padding-top: 6px;
  }
  
  .tableIh{
    width: 0px !important;
  }
  
  .Menu-item{
   background-color: #384089 !important;
   color:#fff !important
  }
  
  .MuiList-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
  }
  
  .horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  
  .delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: "#008fbd";
    background-color: "#fff";
  }
  .delete-user-button:disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }
  
  .table-cell-header {
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  
  .react-search-field-input {
    padding: 0 !important;
    width: 300px;
  }
  
  .headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 17px !important;
  }
  
  .tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5);
  }
  .heading{
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #FFF !important;
    padding-top: 6px;
  }
  
  .tableIh{
    width: 0px !important;
  }
  
  .Menu-item{
   background-color: #384089 !important;
   color:#fff !important
  }
  
  .MuiList-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
  }
  
  .horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  
  .delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: "#008fbd";
    background-color: "#fff";
  }
  .delete-user-button:disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }
  
  .table-cell-header {
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  
  .react-search-field-input {
    padding: 0 !important;
    width: 300px;
  }
  
  .headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 17px !important;
  }
  
  .tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5);
  }
  .heading{
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #FFF !important;
    padding-top: 6px;
  }
  
  .tableIh{
    width: 0px !important;
  }
  
  .Menu-item{
   background-color: #384089 !important;
   color:#fff !important
  }
  
  .MuiList-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
  }
  
  .horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  
  .delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: "#008fbd";
    background-color: "#fff";
  }
  .delete-user-button:disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }
  
  .table-cell-header {
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  
  .react-search-field-input {
    padding: 0 !important;
    width: 300px;
  }
  
  .headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 17px !important;
  }
  
  .tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5);
  }
  .heading{
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #FFF !important;
    padding-top: 6px;
  }
  
  .tableIh{
    width: 0px !important;
  }
  
  .Menu-item{
   background-color: #384089 !important;
   color:#fff !important
  }
  
  .MuiList-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
  }
  
  .horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  
  .delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: "#008fbd";
    background-color: "#fff";
  }
  .delete-user-button:disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }
  
  .table-cell-header {
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  
  .react-search-field-input {
    padding: 0 !important;
    width: 300px;
  }
  
  .headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 17px !important;
  }
  
  .tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5);
  }
  .heading{
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #FFF !important;
    padding-top: 6px;
  }
  
  .tableIh{
    width: 0px !important;
  }
  
  .Menu-item{
   background-color: #384089 !important;
   color:#fff !important
  }
  
  .MuiList-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
.MuiPaper-elevation1 {
    /* box-shadow: 0px 2px 1px -1px #f8f8ff ; */
    box-shadow: 0px 2px 1px -1px #2f3484, 0px 1px 1px 0px #202020, 0px 1px 3px 0px #4b4c63  !important;
}
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
  }
  
  .horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  
  .delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: "#008fbd";
    background-color: "#fff";
  }
  .delete-user-button:disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }
  
  .table-cell-header {
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  
  .react-search-field-input {
    padding: 0 !important;
    width: 300px;
  }
  
  .headerCell {
    color: #384089 !important;
    font-weight: 600 !important;
    font-size: 17px !important;
  }
  
  .tableBorderRightH {
    border-right: 2px solid rgba(224, 224, 224, .5);
    border-bottom: 2px solid rgba(224, 224, 224, .5);
  }
  .heading{
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #FFF !important;
    padding-top: 6px;
  }
  
  .tableIh{
    width: 0px !important;
  }
  
  .Menu-item{
   background-color: #384089 !important;
   color:#fff !important
  }
  
  .MuiList-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.dialog-title-close-icon {
    text-align: end;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 46%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.dialog-box-title {
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #202020;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #202020;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    text-align: center;
}

.makeStyles-dialogPaper-5 {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
}

.add-user .innerDiv{
    border: 1px solid #C2C2C2;
    padding: 1rem;
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.iconAllow{
    color: #384089;
    margin: 5px;
    padding: 1px;
    cursor: pointer;
}

.iconNotAllow{
    color: #827e7e;
    margin: 5px;
    padding: 1px;
    cursor: not-allowed;
}

.grid-container {
    display: grid;
    grid-column-gap: 5px;
  }
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
}

.add-user .innerDiv{
    border: 1px solid #C2C2C2;
    padding: 1rem;
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.heading{
    font-size: 16px;
    color: rgb(90, 87, 87);
    font-weight: normal;
    line-height: 19px;
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}
.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* display: flex; */
}

.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.add-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
}

.delete-user-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
}

.table-cell-header {
    /* border: "1px solid #E5E5E5",
    width: "20%",
    padding: "10px",
    backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
}

.react-search-field-input {
    padding: 0 !important;
    width: 300px;
}

.icon-cursor {
    cursor: pointer;
}
.custom_confirm_dialog .MuiPaper-root{
    width: 403px;
    height: 250px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(103 105 132 / 25%);
    border-radius: 8px;
}
.custom_confirm_dialog_title{
    font-weight: 500 !important;
    font-size: 18px !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button{
    /* color: #264C86 !important; */
}
.custom_confirm_dialog_no_button{
    color: #264C86 !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-family: Roboto !important;
    font-style: normal !important;
    line-height: 21px  !important;
    -webkit-align-items: center  !important;
            align-items: center  !important;
}
.custom_confirm_dialog_sub_title{
    font-size: 13px !important;
}
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-weight: 500 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: none !important;
    border: 1px solid #264C86 !important;
}
.custom_confirm_dialog_yes_button{
    background: #264C86 !important;
    color: #fff !important;
}
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
}

.add-user .innerDiv{
    border: 1px solid #C2C2C2;
    padding: 1rem;
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.iconAllow{
    color: #384089;
    margin: 5px;
    padding: 1px;
    cursor: pointer;
}

.iconNotAllow{
    color: #827e7e;
    margin: 5px;
    padding: 1px;
    cursor: not-allowed;
}

.grid-container {
    display: grid;
    grid-column-gap: 5px;
}

.MuiSelect-select {
    padding: 5px !important;
}
.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
}

.add-user .innerDiv{
    border: 1px solid #C2C2C2;
    padding: 1rem;
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.heading{
    font-size: 16px;
    color: rgb(90, 87, 87);
    font-weight: normal;
    line-height: 19px;
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.MuiSelect-select {
    padding: 5px !important;
}
