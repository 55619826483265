.MuiFilledInput-input{
    padding: 0.8rem !important;
}

.MuiInputBase-input{
    border: 1px solid #E5E5E5 !important;
    background: #ffffff !important;
}

.MuiInputBase-isValidOrInvalid{
    border: 1px solid #84199e !important;
    background: #ffffff !important;
}