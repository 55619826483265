.login-input-border .MuiInput-underline:before .MuiInput-underline:before {
  border-bottom: 0px;
}

.labelTextbox {
  height: 38.56px;
  bottom: 76.77%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #676984 !important;
}

.UserNameInput {
  top: 10% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #676984 !important;
}

.inputRootContainer {
  max-width: 620px !important;
  width: 100% !important;
}

.inputRootContainerForget {
  justify-content: flex-end;
  display: flex;
}

.inputRootSubContainer {
  margin-left: 30px;
  margin-top: 6px;
}

.RemeberMeContainer {
  flex-wrap: nowrap !important;
}

.checkboxRemeberME {
  width: 30px;
}

.loginButton.MuiButton-root:hover {
  background: #264c86 !important;
}

@media only screen and (max-width: 550px) {
  .loginButton {
    width: 92%;
    max-width: 350px;
    margin-bottom: 20px !important;
  }
}

@media only screen and (min-width: 600px) {
  .loginButton {
    width: 160px;
  }
}

.loginButton {
  font-size: 11px;
  text-transform: uppercase;
  background-color: #264c86 !important;
  border: 1.5px solid #264c86;
  color: #ffffff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.errorMessageAlert {
  margin-left: 35px !important;
  margin-top: 10px !important;
  color: #273050;
  background: rgba(192, 29, 29, 0.05);
  border: 1px solid #e96262;
  width: 80%;
  max-width: 592px;
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 10px;
}

.alertMessageImage {
  margin-left: 10px;
  margin-right: 10px;
}

.loginMobileText {
  position: absolute;
  top: 30px;
  left: 20px;
  color: #ffffff;
  font-size: 21px;
  line-height: 26px;
}

@media only screen and (max-width: 550px) {
  .TextBoxMobileView {
    margin-left: 15px;
    width: 95.5%;
  }
}

@media only screen and (min-width: 600px) {
  .TextBoxMobileView {
    margin-left: 25px;
    width: 89%;
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxMobileView {
    margin-right: 8% !important;
    margin-left: 14px !important;
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxMobileView {
    margin-left: 25px;
  }
}

@media only screen and (min-width: 960px) {
  .inputBoxMobileView {
    margin-left: 25px;
    margin-top: 30px
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxMobileView1 {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxMobileView1 {
    margin-left: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .inputBoxMobileView1 {
    margin-left: 25px;
    margin-top: 20px !important
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxPassword {
    margin-left: 13px !important
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxPassword {
    margin-left: 25px;
  }
}

.registerButton{
  margin-top: 20px !important;
  margin-bottom: 20px;
}

@media only screen and (max-width: 550px) {
  .registerButton {
    margin-left: 20px !important;
    margin-right: 5% !important;
  }
}

@media only screen and (min-width: 600px) {
  .registerButton {
    margin-left: 30px !important;
    margin-right: 15% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .registerButton {
    margin-right: 15% !important;
    align-items: end;
    text-align: right;
  }
}

@media only screen and (max-width: 550px) {
  .registerButton1 {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .registerButton1 {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .registerButton1 {
    max-width: 160px;
  }
}

.contactNumber{
  margin-top: 15px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 550px) {
  .contactNumber {
    margin-left: 23px !important;
    width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .contactNumber {
    margin-left: 35px;
    width: 86%
  }
}

.visibilityButton .MuiIconButton-root{
  padding: 0px !important;
}

.react-tel-input .form-control {
  height: 40px !important;
  width: 104% !important;
}

@media only screen and (max-width: 550px) {
  .NewPassword {
    width: 96%
  }
}

@media only screen and (min-width: 600px) {
  .NewPassword {
    width: 91%;
  }
}

@media only screen and (max-width: 550px) {
  .ConfirmPassword {
    width: 96%
  }
}

@media only screen and (min-width: 600px) {
  .ConfirmPassword {
    width: 101%;
  }
}

.MuiOutlinedInput-input{
  border: 0px !important;
}