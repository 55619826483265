.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
    /* width: 60%; */
}

.add-user .innerDiv{
    /* border: 2px solid #E5E5E5; */
    border: 1px solid #C2C2C2;
    padding: 1rem;
    /* margin-top: 2rem; */
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.react-search-field-input{
    padding: 0 !important;
    width: 642px;
}

.MuiAccordion-root.Mui-expanded{
    margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded{
    margin: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded{
    min-height: 0 !important;
}

.MuiAccordionSummary-content{
    margin: 0;
}

.filterBy .MuiFormControl-root .MuiOutlinedInput-root {
    padding: 0px !important;
}

.filterBy .MuiFormControl-root label{
    top: -8px;
}

.search .MuiOutlinedInput-root input{
    border: 0px !important;
}