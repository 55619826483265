.add-user .outerDiv{
    background-color: #ffffff;
    margin-top: 0;
    padding: 2rem;
}

.add-user .innerDiv{
    border: 1px solid #C2C2C2;
    padding: 1rem;
}

.add-user .horizontal-line{
    margin: 0.8rem -1rem 1rem -1rem;
    border-bottom: 1px solid #E5E5E5;
}

.iconAllow{
    color: #384089;
    margin: 5px;
    padding: 1px;
    cursor: pointer;
}

.iconNotAllow{
    color: #827e7e;
    margin: 5px;
    padding: 1px;
    cursor: not-allowed;
}

.grid-container {
    display: grid;
    grid-column-gap: 5px;
}

.MuiSelect-select {
    padding: 5px !important;
}