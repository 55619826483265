.MuiAppBar-colorPrimary {
    color: #2F3484  !important;
    /* background-color: #F8F8FF !important; */
    background-color: #384089 !important;

}

.MuiPaper-elevation4{
    box-shadow: none !important;
}

.menuButton, .menuButton:hover{
    background-color: white !important;
    border-radius: 4px !important;
    margin: -14px !important;
    padding: 0px 10px 0px 0px !important;
}