.nav-container {
  /* height: 100%; */
  height: 88.5vh;
  background-color: "#ffffff";
  overflow-y: hidden;
  overflow-x: hidden;
}

.nav-route-container {
    height: 100%;
    background-color: #F0F0F7;
    padding: 5px;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    /* display: flex; */
}
.horizontal-line {
    margin: 0rem 0.3rem 0.2rem 0.3rem;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
}
  .add-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 21px;
    width: 160px;
    height: 30px;
    background: rgba(0, 143, 189, 0.95);
    border-radius: 3px;
  }
  .delete-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    width: 170px;
    height: 30px;
    border: 1px solid #008fbd;
    box-sizing: border-box;
    border-radius: 3px;
    color: #008fbd;
  }
  .table-cell-header {
    /* border: "1px solid #E5E5E5",
      width: "20%",
      padding: "10px",
      backgroundColor: "#F0F8FF", */
    border: 1px solid #e5e5e5;
    width: 20%;
    padding: 10px !important;
    background-color: #f0f8ff;
  }
  .policy-container {
    border: 1px solid #000 ;
  }

  .search-class{
  color: #000;
  }

  .react-search-field-input{
    padding: 0 !important;
    width: 700px;
  }

  .view-button{
    text-decoration:underline;
    /* border-bottom: 1px solid black; */
  }

.users-all {
   background-color: white !important;
   color: #008fbd !important;
   border: #fff !important;
   min-height: 15px !important;
   font-size: 14px !important;
   padding-top: 10px !important;
   font-weight: 500 !important;
   text-decoration: underline !important;
   padding-left: 30px !important;
}
.users-all:disabled {
  background-color: white !important;
  color: #a9a9a9 !important;
  border: #fff !important;
  min-height: 15px !important;
   font-size: 14px !important;
   padding-top: 10px !important;
   font-weight: 500 !important;
   text-decoration: underline !important;
   padding-left: 30px !important;
}

.account-user{
  font-size: "14px" !important;
  width: "190px" !important;
  color: "black" !important;
  padding-top: "5px" !important;
  padding-left: "9000px" !important;
}

.Menu-item{
  background-color: #384089 !important;
  color:#fff !important
 }