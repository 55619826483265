.MuiButton-root.MuiButton-root, .MuiButton-root.MuiButton-root:hover{
    color: #FFFFFF;
    /* background-color: #008FBD;
    border: 1.5px solid #008FBD; */
    background-color: #384089;
    border: 1.5px solid #384089;
    min-height: 35px;
    min-width: 100px;
    height: 35px;
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.MuiButton-root.Mui-disabled {
    background-color: #f0f8ff !important;
    border: 1.5px solid #a9a9a9 !important;
    color: #a9a9a9 !important;
  }

/* Custom, galaxy fold*/ 
@media only screen and (min-width : 280px) {
    .MuiButton-root.MuiButton-root{
        height: 50px;
    }
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    .MuiButton-root.MuiButton-root{
    height: 50px;
 }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    .MuiButton-root.MuiButton-root{
        height: 50px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .MuiButton-root.MuiButton-root{
        height: 35px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .MuiButton-root.MuiButton-root{
        height: 35px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .MuiButton-root{
        height: 35px;
    }
}