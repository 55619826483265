.reset-password .outerDiv {
  background-color: #ffffff;
  margin-top: 2rem;
  padding: 2rem;
  /* width: 60%; */
}

.reset-password .innerDiv {
  /* border: 2px solid #E5E5E5; */
  border: 1px solid #c2c2c2;
  padding: 1rem;
  /* margin-top: 2rem; */
}

.reset-password .horizontal-line {
  margin: 0.8rem -1rem 1rem -1rem;
  border-bottom: 1px solid #e5e5e5;
}

.textCaption {
  font-weight: 700 !important;
  font-size: 26px !important;
  line-height: 30px;
  margin-left: 40px !important;
  max-width: 313px;
  color: #254b83;
}

@media only screen and (min-width: 800px) {
  .textCaption {
    margin-top: 240px !important;
  }
}

@media only screen and (max-width: 780px) {
  .textCaption {
    margin-top: 130px !important;
  }
}

.loginMobileTextCaption {
  position: absolute;
  top: 70px;
  left: 20px;
  color: #ffffff;
  font-size: 28px;
  line-height: 26px;
}

.confirmButtonReset {
  margin-top: 30px !important;
  /* margin-left: 35px !important; */
  /* width: 120px; */
  font-size: 20px;
}

@media only screen and (min-width: 800px) {
  .confirmButtonReset {
    width: 120px !important;
    margin-left: 35px !important;
  }
}

@media only screen and (min-width: 760px) {
  .confirmButtonReset {
    width: 75%;
    margin-left: 35px !important;
  }
}

@media only screen and (max-width: 750px) {
  .confirmButtonReset {
    width: 80%;
    margin-left: 25px !important;
    height: 35px !important;
  }
}