.root {
  width: 100vw;
  height: 88.5vh;
  overflow: hidden;
  display: flex;
  /* flex-grow: 1; */
  background-color: #F0F0F7;
}

.nav-container {
  /* height: 100%; */
  height: 88.5vh;
  background-color: "#ffffff";
  overflow-y: hidden;
  overflow-x: hidden;
}

.nav-route-container {
  height: 100%;
  background-color: #F0F0F7;
  padding: 5px;
  /* display: flex; */
}
.nav-route-inner-container {
  height: 100%;
  margin: 10px;
  background-color: green;
}
.horizontal-line {
  margin: 0rem 0.3rem 0.2rem 0.3rem;
  border-bottom: 1px solid #e5e5e5;
}
.navigation-top-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-transform: uppercase;

  /* Heading */

  color: #4b4c63;
}
.navigation-main-item-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: normal;
  font-size: "14px";
  line-height: "19px";
  /* identical to box height */

  text-transform: uppercase;

  /* Heading */

  color: #4b4c63;
}
.navigation-subitem-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: normal;
  font-size: "14px";
  line-height: "19px";
  /* identical to box height */

  text-transform: uppercase;

  /* Heading */

  color: #4b4c63;
}
.dashboard-right-view {
  background-color: #ffffff;
  /* height: 100vh; */
  padding: 2rem;
}

.MuiDivider-root.MuiDivider-root {
  width: 100%;
  background-color: #c2c2c2;
  /* background-color: #525252; */
  margin: 0.8rem 0 1rem 0;
}
.option-second-row {
  margin-top: 2rem !important;
}
